$(document).ready(async () => {
  $('#product_unit_price').focusout(function () {

    let is_tax_excluded = $('#tax_excluded').val()
    if(!is_tax_excluded){
      let unit_price = $(this).val()
      let gross_margin = $('#product_gross_margin').val()
      let purchase_price = $('#product_purchase_price').val()
      $('#product_outdoor_price').val('')
      if(unit_price && purchase_price) {
        let prices = calculateGrossMargin(unit_price, gross_margin, purchase_price)
        calculateOutDoorPrice(unit_price, null, purchase_price)
        $('#product_gross_margin').val(prices.gross_margin)
      }
    }
  });

  $('#product_purchase_price').focusout(function () {

    let is_tax_excluded = $('#tax_excluded').val()
    if(!is_tax_excluded){
      let unit_price = $('#product_unit_price').val()
      let gross_margin = $('#product_gross_margin').val()
      let purchase_price = $(this).val()
      if(gross_margin && purchase_price) {
        let prices = calculateUnitPrices(unit_price, gross_margin, purchase_price)
        $('#product_unit_price').val(prices.unit_price)
      }
    }
  });

  $('#product_gross_margin').focusout(function () {

    let is_tax_excluded = $('#tax_excluded').val()
    if(!is_tax_excluded){
      let gross_margin = $(this).val()
      if (gross_margin >= 100){
      alert('Must be less than 100')
      }else{
        let unit_price = $('#product_unit_price').val()
        let purchase_price = $('#product_purchase_price').val()
        if(gross_margin && purchase_price) {
          let prices = calculateUnitPrices(null, gross_margin, purchase_price)
          $('#product_unit_price').val(prices.unit_price)
        }
      }
    }
  });
  $('#product_outdoor_price').focusout(function () {
    let unit_price = $('#product_unit_price').val()
    let purchase_price = $('#product_purchase_price').val()
    let outdoor_price = $('#product_outdoor_price').val()
    if(unit_price && purchase_price) {
      calculateOutDoorPrice(unit_price, outdoor_price, purchase_price)
    }
  });

  function calculateUnitPrices(unit_price, gross_margin, purchase_price) {
    unit_price = 100 * purchase_price /(100 - gross_margin)
    if(unit_price && purchase_price) {
      calculateOutDoorPrice(unit_price, null, purchase_price)
    }

    return {
      unit_price: unit_price
    }
  }
  function calculateGrossMargin(unit_price, gross_margin, purchase_price) {
    gross_margin = (unit_price - purchase_price) * 100 / unit_price
    console.log('gross_margin', gross_margin)
    return {
      gross_margin: Math.round(gross_margin)
    }
  }

  let progress = null
  function calculateOutDoorPrice(unit_price, outdoor_price, purchase_price) {
    if(progress != null){
      progress.abort();
    }
    progress =  $.ajax({
      type: 'POST',
      url: '/calculate_outdoor_price',
      data:{
        unit_price: unit_price,
        purchase_price: purchase_price,
        cannabis_percentage: $('#product_cannabis_percentage').val(),
        product_id: $('#productId').val(),
        accessories_percentage: $('#product_accessories_percentage').val(),
        outdoor_price: outdoor_price,
        item_type: $('#product_item_type').val()
      },
      success: function (data) {
        if (data.success) {
          $('#product_outdoor_price').val(data.prices.outdoor_price)
          $('#product_gross_margin').val(data.prices.gross_margin)
          $('#product_unit_price').val(data.prices.unit_price)
          progress = null
        }
      }, error: function (error) {
        console.log('error', error);
      }
  })

  }
})
